
import { hashTextInSHA256 } from '~/helpers/util'
import modalConstants from '~/store/modal/-constants'
import { WebRequest } from '~/helpers/api'

export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}
export default {
  name: 'MoleculeRequestOffer',
  data() {
    return {
      errors: {},
      inputs: {
        needsMortgage: false,
        isMortgageApproved: false,
        isInvestment: false,
        openToOtherLocations: false,
        visitedOtherProjects: false,
        ownsVehicle: false,
        needsPublicTransport: false
      },
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      budget: '',
      requiredUtilSurface: '',
      notes: '',
      date: '',
      inputTypes: EnumInputTypes
    }
  },
  computed: {
    modalData() {
      return this.$store.state.modal.data
    },
    requestedSpaces() {
      if (this.modalData) {
        return [this.modalData.space]
      }
      return this.$store.state.requestOffer.spaces
    },
    client() {
      return this.$config.CLIENT
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    }
  },
  methods: {
    sqmInfo(space) {
      // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
      // const balconySurface =
      //   space?.units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())
      //     ?.surface || 0
      // const gardenSurface =
      //   space?.units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())
      //     ?.surface || 0
      return {
        // total_sqm: Number((space.constructed_sqm + balconySurface + gardenSurface).toFixed(2)),
        // useful_sqm: Number((space.util_sqm + balconySurface + gardenSurface).toFixed(2))
        total_sqm: Number(space.constructed_sqm.toFixed(2)),
        useful_sqm: Number(space.util_sqm.toFixed(2))
      }
    },
    async submitForm(e) {
      e.preventDefault()
      if (this.validateInputs() !== null) return
      if (window && 'dataLayer' in window) {
        window.dataLayer.push({
          event: 'request_offer_submit',
          category: 'request_offer',
          label: 'request_offer'
        })
      }

      const selectedSpaces = this.requestedSpaces.map(({ id }) => {
        return { id }
      })

      const payload = {
        deal_name: this.lastName + ' ' + this.firstName,
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phone,
        budget: this.budget,
        required_util_surface: this.requiredUtilSurface,
        needs_mortgage: this.inputs.needsMortgage === '' ? null : this.inputs.needsMortgage,
        is_mortgage_approved:
          this.inputs.isMortgageApproved === '' ? null : this.inputs.isMortgageApproved,
        is_investment: this.inputs.isInvestment === '' ? null : this.inputs.isInvestment,
        open_to_other_locations:
          this.inputs.openToOtherLocations === '' ? null : this.inputs.openToOtherLocations,
        visited_other_projects:
          this.inputs.visitedOtherProjects === '' ? null : this.inputs.visitedOtherProjects,
        owns_vehicle: this.inputs.ownsVehicle === '' ? null : this.inputs.ownsVehicle,
        needs_public_transport:
          this.inputs.needsPublicTransport === '' ? null : this.inputs.needsPublicTransport,
        email: this.email,
        notes: this.notes === '' ? null : this.notes,
        selected_spaces: selectedSpaces,
        status: 0
      }

      let remarks = null
      let proposedVisitDate = null
      const selector = 'input[type=radio]:checked'
      const checkedRadio = this.$el.querySelector(selector)
      if (checkedRadio) {
        const checkedRadioType = checkedRadio.parentNode.getAttribute('data-box')
        switch (checkedRadioType) {
          case 'no-visit':
            remarks = 'No visit date'
            payload.remarks = remarks
            break
          case 'interval': {
            remarks = `Visit date: ${
              checkedRadio.parentNode.parentNode
                .querySelector(".hide-select[data-tab='select-interval']")
                .querySelector(selector).value
            }`
            payload.remarks = remarks
            break
          }
          case 'date': {
            proposedVisitDate = this.date
            payload.proposed_visit_date = proposedVisitDate
            break
          }
        }
      }

      if (proposedVisitDate === null) {
        delete payload.proposed_visit_date
      }
      if (remarks === null) {
        delete payload.remarks
      }

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_conversion',
          payload
        })
      }

      const {
        data: { error: serverError, result }
      } = await WebRequest.POST(this.$webApi.landlord.requestOffer(), payload)
      if (serverError) {
        alert('Something wrong happened! Please try again!')
        return
      }
      if (this.client === 'one' && this.$config.RUNTIME_ENV === 'production') {
        if ('gtag_report_conversion' in window) {
          window.gtag_report_conversion()
        }
        if ('lintrk' in window) {
          window.lintrk('track', { conversion_id: 4403868 })
        }
      }
      this.$refs['request-offer-form'].reset()
      this.$parent.$refs.successMessage.toggleShow()
      this.convertMetaLeadEvent()
    },
    validateInputs() {
      this.errors = {}
      if (this.companyName === '') {
        this.errors.companyName = this.$t('Company name is required.')
      }
      if (this.firstName === '') {
        this.errors.firstName = this.$t('First Name is required.')
      }
      if (this.lastName === '') {
        this.errors.lastName = this.$t('Last Name is required.')
      }
      if (this.budget === '') {
        this.errors.budget = this.$t('Budget is required.')
      }
      if (isNaN(this.budget)) {
        this.errors.budget = this.$t('Budget should be a number.')
      }
      if (this.requiredUtilSurface === '') {
        this.errors.requiredUtilSurface = this.$t('Surface is required.')
      }
      if (isNaN(this.requiredUtilSurface)) {
        this.errors.requiredUtilSurface = this.$t('Surface should be a number')
      }
      if (this.phone === '' || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = this.$t('Phone Number is not valid.')
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = this.$t('Email is not valid.')
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = this.$t('Please agree to the Terms and Conditions first.')
      }

      // const checkedRadio = this.$el.querySelector('input[type=radio]:checked');
      // if (checkedRadio === null) {
      //   this.errors.generalRadioGroup = 'Please select one option.';
      // } else {
      //   const checkedRadioType = checkedRadio.parentNode.getAttribute('data-box');
      //   switch (checkedRadioType) {
      //     case 'interval': {
      //       const parentHiddenNode = checkedRadio.parentNode.parentNode.querySelector(".hide-select[data-tab='select-interval']");
      //       this.errors.generalRadioGroup = 'Please select an interval.';
      //       if (parentHiddenNode.querySelector('input[type=radio]:checked') !== null) {
      //         delete this.errors.generalRadioGroup;
      //       }
      //       break;
      //     }
      //     case 'date': {
      //       if (this.date === '') {
      //         this.errors.generalRadioGroup = 'Please select a date.';
      //       }
      //       break;
      //     }
      //   }
      // }

      if (Object.keys(this.errors).length === 0) {
        return null
      }
      return this.errors
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    handleCheck(val, inputName) {
      this.inputs[inputName] = val
    },
    closeModal() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_cancel',
          payload: {}
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CLOSE_MODAL))
      document.body.classList.remove('disable-scroll')
    },
    getMappedFloorText(floor) {
      let text = ''
      // TODO: refactor this Petrichi fix :P
      switch (parseInt(floor, 10)) {
        case -3:
          text = `${this.$t('FloorRo')} -3<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -2:
          text = `${this.$t('FloorRo')} -2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -1:
          text = `${this.$t('FloorRo')} -1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 0:
          text = `${this.$t('Ground Floor')}`
          break
        case 1:
          text = `${this.$t('FloorRo')} 1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 2:
          text = `${this.$t('FloorRo')} 2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 3:
          text = `${this.$t('FloorRo')} 3<sup class="superior-text">${this.$t(
            'rd'
          )}</sup>  ${this.$t('Floor')}`
          break
        default:
          text = `${this.$t('FloorRo')} ${floor}<sup class="superior-text">${this.$t(
            'th'
          )}</sup>  ${this.$t('Floor')}`
      }
      return text
    },
    async convertMetaLeadEvent() {
      if (!window || !('fbq' in window)) return
      const date = new Date()
      const unixTimestampInSeconds = Math.floor(date.getTime() / 1000)
      const data = [
        {
          event_name: 'Lead',
          event_time: unixTimestampInSeconds,
          action_source: 'website',
          event_source_url: window.location.href,
          user_data: {
            em: await hashTextInSHA256(this.email)
          }
        }
      ]
      window.fbq('track', 'Lead', data)
    }
  }
}
